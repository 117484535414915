<template>
    <div class="organize-cell flex-row flex-j-start flex-a-center" @click="onClick">
        <lazy-img class="image" :src="item.TitleImgUrl"></lazy-img>
        <div class="flex-col flex-j-around flex-a-start" style="height: 2.6rem;margin-left: 0.2rem">
            <div class="title">{{item.TitleName}}</div>
            <span v-if="item.ProfitType==2 && item.InfoType==1" style="color: #f1676d;">人力资源产业园服务机构推介</span>
            <span v-if="item.ProfitType==2 && item.InfoType==2" style="color: #4eabe8;">其他人力资源服务机构推介</span>
            <div class="time">
                <span>{{item.timeText}}</span>
                <span style="margin-left: 0.4rem;">浏览：{{item.ViewTimes}}</span>
            </div>
        </div>
    </div>
</template>

<script>
// organize-cell
import LazyImg from "@/components/lazy-img";
export default {
    name: "organize-cell",
    components: {LazyImg},
    props: ['item'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        onClick() {
            this.$router.push(`/organize-detail?id=${this.item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.organize-cell {
    background-color: #fff;
    margin-bottom: 0.2rem;
    padding: 0.2rem;
    border-radius: 0.12rem;
    text-align: left;
    & .image {
        width: 4.0rem;
        border-radius: 0.12rem;
        height: 2.6rem;
        overflow: hidden;
    }
    & .title {
        font-size: 0.36rem;
    }
    & .time {
        font-size: 0.32rem;
        color: #666;
    }
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
    }
}
</style>
